//import axios from "axios";

//import { useState } from "react";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import Loading from "./Loading";
import axios from "axios";
import path from "../path"
import { Form, Table } from "react-bootstrap";
import InsuranceListActions from "./listActine/InsuranceListActions";
//import { useEffect, useState } from "react";
const InsurancesTable=forwardRef(({loginOk} , ref) => 

//function InsurancesTable()
{
    //const path='https://dev.ps-on-line.com/api/'
    //const ar=["filterMobile","testB"]
    const [loading,setLoading]=useState(true)
    const [tableData,setTableData]=useState([])
    const [tableDataTemp,setTableDataTemp]=useState([])
    const ar=["filterNumper","filterOwner","filterDate","filterModel","filterCarOwner","filterCarNumper","filterMobile","filterRealValue","filterAgreedValue","filterAmountPaid","filterNeedValuse","filterNextDate","filterNotes"]
    const [filterData,setfilterData]=useState({
        
        filterNumper:"",
        filterOwner:"",
        filterDate:"",
        filterModel:"",
        filterCarOwner:"",
        filterCarNumper:"",
        filterMobile:"",
        filterRealValue:"",
        filterAgreedValue:"",
        filterAmountPaid:"",
        filterNeedValuse:"",
        filterNextDate:"",
        filterNotes:""

    })
    useImperativeHandle(ref, () => ({

        updateTable()
        {
            readData()
        }
      }))
    // const myFilter = (item)=>{
    //     const c=(item.agreed_value-item.amount_paid).toString()
    //     return (item.number.includes(filterData.filterNumper) || filterData.filterNumper===null || filterData.filterNumper.trim()==="") &&
    //     (item.insurance_owner_name.includes(filterData.filterOwner) || filterData.filterOwner===null || filterData.filterOwner.trim()==="")&&
    //     (item.insurances_date.includes(filterData.filterDate) || filterData.filterDate===null || filterData.filterDate.trim()==="")&&
    //     ((item.modle+" "+item.manufacture).toLowerCase().includes(filterData.filterModel.toLowerCase()) || filterData.filterModel===null || filterData.filterModel.trim()==="") &&
    //     (item.car_owner.toLowerCase().includes(filterData.filterCarOwner.toLowerCase()) || filterData.filterCarOwner===null || filterData.filterCarOwner.trim()==="") &&
    //     (item.car_number.toLowerCase().includes(filterData.filterCarNumper.toLowerCase()) || filterData.filterCarNumper===null || filterData.filterCarNumper.trim()==="") &&
    //     (item.insurance_owner_mobile.toLowerCase().includes(filterData.filterMobile.toLowerCase()) || filterData.filterMobile===null || filterData.filterMobile.trim()==="") &&
    //     (item.actual_value.toLowerCase().includes(filterData.filterRealValue.toLowerCase()) || filterData.filterRealValue===null || filterData.filterRealValue.trim()==="") &&
    //     (item.agreed_value.toLowerCase().includes(filterData.filterAgreedValue.toLowerCase()) || filterData.filterAgreedValue===null || filterData.filterAgreedValue.trim()==="") &&
    //     (item.amount_paid.toLowerCase().includes(filterData.filterAmountPaid.toLowerCase()) || filterData.filterAmountPaid===null || filterData.filterAmountPaid.trim()==="") &&    
    //     (c.includes(filterData.filterNeedValuse) ) &&
    //     (item.insurances_end_date.toLowerCase().includes(filterData.filterNextDate.toLowerCase()) || filterData.filterNextDate===null || filterData.filterNextDate.trim()==="") &&
    //     (item.note.toLowerCase().includes(filterData.filterNotes.toLowerCase()) || filterData.filterNotes===null || filterData.filterNotes.trim()==="") 

    
    // }
    useEffect(()=>{
        console.log(filterData)

            console.log("its more than 0");
            //console.log("tableDataTemp="+tableDataTemp)
            const f=tableDataTemp.filter((item)=>{
                const c=(item.agreed_value-item.amount_paid).toString()
                return (item.number.includes(filterData.filterNumper) || filterData.filterNumper===null || filterData.filterNumper.trim()==="") &&
                (item.insurance_owner_name.includes(filterData.filterOwner) || filterData.filterOwner===null || filterData.filterOwner.trim()==="")&&
                (item.insurances_date.includes(filterData.filterDate) || filterData.filterDate===null || filterData.filterDate.trim()==="")&&
                ((item.modle+" "+item.manufacture).toLowerCase().includes(filterData.filterModel.toLowerCase()) || filterData.filterModel===null || filterData.filterModel.trim()==="") &&
                (item.car_owner.toLowerCase().includes(filterData.filterCarOwner.toLowerCase()) || filterData.filterCarOwner===null || filterData.filterCarOwner.trim()==="") &&
                (item.car_number.toLowerCase().includes(filterData.filterCarNumper.toLowerCase()) || filterData.filterCarNumper===null || filterData.filterCarNumper.trim()==="") &&
                (item.insurance_owner_mobile.toLowerCase().includes(filterData.filterMobile.toLowerCase()) || filterData.filterMobile===null || filterData.filterMobile.trim()==="") &&
                (item.actual_value.toLowerCase().includes(filterData.filterRealValue.toLowerCase()) || filterData.filterRealValue===null || filterData.filterRealValue.trim()==="") &&
                (item.agreed_value.toLowerCase().includes(filterData.filterAgreedValue.toLowerCase()) || filterData.filterAgreedValue===null || filterData.filterAgreedValue.trim()==="") &&
                (item.amount_paid.toLowerCase().includes(filterData.filterAmountPaid.toLowerCase()) || filterData.filterAmountPaid===null || filterData.filterAmountPaid.trim()==="") &&    
                (c.includes(filterData.filterNeedValuse) ) &&
                (item.insurances_end_date.toLowerCase().includes(filterData.filterNextDate.toLowerCase()) || filterData.filterNextDate===null || filterData.filterNextDate.trim()==="") &&
                (item.note.toLowerCase().includes(filterData.filterNotes.toLowerCase()) || filterData.filterNotes===null || filterData.filterNotes.trim()==="") 
            
            })
            setTableData(f)
            //console.log(f)
    },[filterData,tableDataTemp])
    const handelOnChangeFilter = (e) => {
        const value = e.target.value.trim()
        const name = e.target.name
        setfilterData({...filterData, [name]: value})
        
    }
    const readData=()=>
    {
        //const auth=localStorage.getItem('auth')
        //const postData={'auth': auth}
        
        axios.get(path+'insurances.php?do=insurance')
        .then(res => {
        console.log("data="+res.data.data);
        console.log("result="+res.data.result);
        setLoading(false)
        if (res.data.result === 1)
        {
            setTableData(res.data.data) ;
            setTableDataTemp(res.data.data) ;
            
        }
        else
        {
            console.log(res.data.massage)
        }
        
        //console.log(res.data.res );
        
        })
        .catch(err => {
            console.log(err)
            setLoading(false)
        })
    }
    function removeTableItem(insurance){
        const index = tableData.indexOf(insurance);

        //const x = myArray.splice(index, 1);
        //alert("index="+index)
        setTableData(
            [
                ...tableData.slice(0, index),
                ...tableData.slice(index + 1)
              ]
        )
    }
    useEffect(()=> {
        if(loginOk)  readData()
    },[loginOk])
    if (loading) 
    {
        return (<Loading />)
    }
    if (tableData.length+tableDataTemp.length===0)
    {
        return (<div className="container">القائمة فارغة يمكنك انشاء تامين جديد</div>)
    }
    return (<div className="container">
        <h1>قائمة التامينات</h1>
        {/* <InsurancesTable /> */
        // className="table table-bordered border-primary"
        }
        {/* <table className="table table-dark table-striped"> */}
        <Table striped bordered hover variant="dark">
            <thead>
                <tr>
                    <th>#</th>
                    <th>رقم التامين</th>
                    <th>مالك التامين</th>
                    <th>تاريخ التامين</th>
                    <th>طراز السيارة</th>
                    <th>مالك السيارة</th>
                    <th>رقم السيارة</th>
                    <th>رقم التلفون</th>
                    <th>القيمة الفعلية</th>
                    <th>القيمة المتفق عليها</th>
                    <th>المبلغ المدفوع</th>
                    <th>المبلغ المتبقي</th>
                    <th>تاريخ الدفعه القادمة</th>
                    <th>ملاحظات</th>
                    <th>االإجراءات</th>
                </tr>
                <tr>
                    <th> </th>
                    {
                        ar.map((data) => ( <th>
                            <Form.Control
                                type="text"
                                name={data}
                                value={filterData[data]}
                                onChange={handelOnChangeFilter}
                            />
                        </th>))
                    }
                    <th> </th>
                </tr>
            </thead>
            <tbody>
                {
                    tableData.map((data) => (<tr key={data.id}>
                        <td>{data.id}</td>
                        <td>{data.number}</td>
                        <td>{data.insurance_owner_name}</td>
                        <td>{data.insurances_date}</td>
                        <td>{data.manufacture} {data.modle}</td>
                        <td>{data.car_owner}</td>
                        <td>{data.car_number}</td>
                        <td>{data.insurance_owner_mobile}</td>
                        <td>{data.actual_value}</td>
                        <td>{data.agreed_value}</td>
                        <td>{data.amount_paid}</td>
                        <td>{data.agreed_value-data.amount_paid}</td>
                        <td>{data.insurances_end_date}</td>
                        <td>{data.note}</td>
                        <td><InsuranceListActions insurance={data} removeTableItem={removeTableItem} /></td>
                    </tr>
                       ))
                    //console.log("tableData=" +tableData)
                }
            </tbody>

        </Table>
    </div>)
    
    
})
export default InsurancesTable